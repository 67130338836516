.leftHighlightButton {
    color: black;
    transition: all 0.3s;
    position: relative;
    line-height: 50px;
    min-height: 50px;
    text-align: center;
    min-width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;

    .buffer {
        width: 0%;
        height: 100%;
        margin-left: 15%;
    }
    .lineHighlight {
        border-left: var(--borderColor) solid 2px;
        transform: scaleY(0);
        transition: transform 0.26s ease;
    }
}
.leftHighlightButton span {
    transition: all 0.3s;
}
.leftHighlightButton:hover span {
    letter-spacing: 2px;
}
.leftHighlightButton:hover {
    .buffer {
        transform: scaleY(0.9);
    }
}
.Selected {
    .lineHighlight {
        border-left: var(--borderColor) solid 6px;
        transform: scaleY(0.9);
    }
}
