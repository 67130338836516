//Phone
@media (max-width: 600px) {
    .oneButton {
        display: flex;
        justify-content: flex-start;
        width: 100%;

        &.middle {
            justify-content: center;
        }
    }

    .twoButton {
        display: flex;
        justify-content: space-between;
    }

    .threeButton {
        display: flex;
        justify-content: space-between;
    }

    .contentContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
    }

    .infoContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        width: 100%;
        text-align: center;
        min-height: 350px;
    }

    #SignupBodyContainer {
        left: 0;
        right: 0;
        z-index: 1;
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        #FormTitle {
            background-color: silver;
            width: 487px;
            padding: 10px 0px 10px 30px;
        }

        #FormContainer {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin-top: 100px;
            // min-height: 600px;
        }
        // .form-control_error {
        //     @extend .form-control;
        //     border-color: red;
        // }

        .h1-header {
            font-weight: 100;
            margin-top: 0;
            font-size: 36px;
            background: silver;
            padding: 25px;
            margin-left: -10px;
            width: 425px;
            position: absolute;
        }
        // @media (max-width: 480px) {
        //     .h1-header {
        //         position: static;
        //         font-weight: 100;
        //         margin-top: 0;
        //         font-size: 18px;
        //         background: transparent;
        //         padding: 25px;
        //         margin-left: -10px;
        //         /* width: 400px; */
        //         border-bottom: 1px solid white;
        //     }
        // }
        // @media only screen and (min-device-width: 320px) and (max-device-width: 736px) and (orientation: landscape) {
        //     .h1-header {
        //         position: static;
        //         font-weight: 100;
        //         margin-top: 0;
        //         font-size: 18px;
        //         background: transparent;
        //         padding: 0 0 10px 20px;
        //         margin-left: -10px;
        //         border-bottom: 1px solid white;
        //     }
        // }

        .btn-default,
        .btn-default:hover,
        .btn-default:focus {
            color: #333;
            text-shadow: none; /* Prevent inheritence from `body` */
            background-color: #fff;
            border: 1px solid #fff;
        }
        .btn-lg {
            border-radius: 0;
            padding: 10px 20px;
            font-size: 20px;
        }
        .btn-default,
        .btn-default:hover,
        .btn-default:focus {
            color: #fff;
            text-shadow: none;
            background-color: #2ecc71;
            border: none;
        }
    }

    .progress {
        border-radius: 0;
        margin-bottom: 0;
        height: 7px;
        background-color: #1e6697;
        -webkit-box-shadow: none;
        box-shadow: none;

        .progress-bar {
            background-color: #2ecc71;
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }

    .hide {
        display: none;
    }

    .errorMessage {
        color: red;
    }

    .clickableText {
        background: none;
        border: none;
        &:hover {
            cursor: pointer;
        }
    }
}

//Tablet
// @media (min-width: 600px) and (max-width: 1080px) {
//     .oneButton {
//         display: flex;
//         justify-content: flex-start;
//         width: 100%;

//         &.middle {
//             justify-content: center;
//         }
//     }

//     .twoButton {
//         display: flex;
//         justify-content: space-between;
//     }

//     .threeButton {
//         display: flex;
//         justify-content: space-between;
//     }

//     .contentContainer {
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         margin: 30px;
//     }

//     .infoContainer {
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         justify-content: start;
//         width: 60vw;
//         height: 30vh;
//     }

//     #SignupBodyContainer {
//         left: 0;
//         right: 0;
//         z-index: 1;
//         display: block;
//         width: 100%;
//         height: 100%;
//         overflow: hidden;
//         #FormTitle {
//             background-color: silver;
//             width: 487px;
//             padding: 10px 0px 10px 30px;
//         }

//         #FormContainer {
//             display: flex;
//             justify-content: center;
//             flex-direction: column;
//             align-items: center;
//             margin-top: 100px;
//             // min-height: 600px;
//         }
//         // .form-control_error {
//         //     @extend .form-control;
//         //     border-color: red;
//         // }

//         .h1-header {
//             font-weight: 100;
//             margin-top: 0;
//             font-size: 36px;
//             background: silver;
//             padding: 25px;
//             margin-left: -10px;
//             width: 425px;
//             position: absolute;
//         }
//         // @media (max-width: 480px) {
//         //     .h1-header {
//         //         position: static;
//         //         font-weight: 100;
//         //         margin-top: 0;
//         //         font-size: 18px;
//         //         background: transparent;
//         //         padding: 25px;
//         //         margin-left: -10px;
//         //         /* width: 400px; */
//         //         border-bottom: 1px solid white;
//         //     }
//         // }
//         // @media only screen and (min-device-width: 320px) and (max-device-width: 736px) and (orientation: landscape) {
//         //     .h1-header {
//         //         position: static;
//         //         font-weight: 100;
//         //         margin-top: 0;
//         //         font-size: 18px;
//         //         background: transparent;
//         //         padding: 0 0 10px 20px;
//         //         margin-left: -10px;
//         //         border-bottom: 1px solid white;
//         //     }
//         // }

//         .btn-default,
//         .btn-default:hover,
//         .btn-default:focus {
//             color: #333;
//             text-shadow: none; /* Prevent inheritence from `body` */
//             background-color: #fff;
//             border: 1px solid #fff;
//         }
//         .btn-lg {
//             border-radius: 0;
//             padding: 10px 20px;
//             font-size: 20px;
//         }
//         .btn-default,
//         .btn-default:hover,
//         .btn-default:focus {
//             color: #fff;
//             text-shadow: none;
//             background-color: #2ecc71;
//             border: none;
//         }
//     }

//     .progress {
//         border-radius: 0;
//         margin-bottom: 0;
//         height: 7px;
//         background-color: #1e6697;
//         -webkit-box-shadow: none;
//         box-shadow: none;

//         .progress-bar {
//             background-color: #2ecc71;
//             -webkit-box-shadow: none;
//             box-shadow: none;
//         }
//     }

//     .hide {
//         display: none;
//     }

//     .errorMessage {
//         color: red;
//     }

//     .clickableText {
//         background: none;
//         border: none;
//         &:hover {
//             cursor: pointer;
//         }
//     }
// }

// PC
@media (min-width: 600px){
// @media (min-width: 1080px) {
    #SignupBodyContainer {
        left: 0;
        right: 0;
        z-index: 1;
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        #FormTitle {
            background-color: silver;
            width: 487px;
            padding: 10px 0px 10px 30px;
        }

        #FormContainer {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin-top: 100px;
            // min-height: 600px;
        }
        // .form-control_error {
        //     @extend .form-control;
        //     border-color: red;
        // }

        .oneButton {
            display: flex;
            justify-content: flex-start;
            width: 100%;

            &.middle {
                justify-content: center;
            }
        }

        .twoButton {
            display: flex;
            justify-content: space-between;
        }

        .threeButton {
            display: flex;
            justify-content: space-between;
        }

        .contentContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 550px;
        }

        .infoContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: start;
            width: 30vw;
            height: 500px;
        }

        .h1-header {
            font-weight: 100;
            margin-top: 0;
            font-size: 36px;
            background: silver;
            padding: 25px;
            margin-left: -10px;
            width: 425px;
            position: absolute;
        }
        // @media (max-width: 480px) {
        //     .h1-header {
        //         position: static;
        //         font-weight: 100;
        //         margin-top: 0;
        //         font-size: 18px;
        //         background: transparent;
        //         padding: 25px;
        //         margin-left: -10px;
        //         /* width: 400px; */
        //         border-bottom: 1px solid white;
        //     }
        // }
        // @media only screen and (min-device-width: 320px) and (max-device-width: 736px) and (orientation: landscape) {
        //     .h1-header {
        //         position: static;
        //         font-weight: 100;
        //         margin-top: 0;
        //         font-size: 18px;
        //         background: transparent;
        //         padding: 0 0 10px 20px;
        //         margin-left: -10px;
        //         border-bottom: 1px solid white;
        //     }
        // }

        .btn-default,
        .btn-default:hover,
        .btn-default:focus {
            color: #333;
            text-shadow: none; /* Prevent inheritence from `body` */
            background-color: #fff;
            border: 1px solid #fff;
        }
        .btn-lg {
            border-radius: 0;
            padding: 10px 20px;
            font-size: 20px;
        }
        .btn-default,
        .btn-default:hover,
        .btn-default:focus {
            color: #fff;
            text-shadow: none;
            background-color: #2ecc71;
            border: none;
        }
    }

    .progress {
        border-radius: 0;
        margin-bottom: 0;
        height: 7px;
        background-color: #1e6697;
        -webkit-box-shadow: none;
        box-shadow: none;

        .progress-bar {
            background-color: #2ecc71;
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }

    .hide {
        display: none;
    }

    .errorMessage {
        color: red;
    }

    .clickableText {
        background: none;
        border: none;
        &:hover {
            cursor: pointer;
        }
    }
}
