.HelpDeskContent {
    position: fixed;
    left: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: calc(100vw - 250px);
    transform: translate(250px);
    height: calc(100% - 50px);

    .Filters {
        .FilterEmptySpace {
            width: 100%;
        }

        .FilterIcon {
            height: 40px;
            cursor: pointer;
        }

        .FilterInputs {
            display: flex;
            justify-content: space-evenly;
            height: 100px;
            margin-top: -22px;
            .DateFilter {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin: 0px 5px 0px 5px;
            }
        }
    }

    &.open {
        animation-name: scaleContentOut;
        animation-duration: 0.6s;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.43, 0.43, 0.05, 0.99);
    }

    &.closed {
        animation-name: scaleContentIn;
        animation-duration: 0.6s;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.68, 0.16, 0.27, 1);
    }

    .Header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 90%;
        height: 100px;
    }

    .HelpDeskTable {
        width: 90%;
        height: -webkit-fill-available;
        margin-bottom: 30px;

        th {
            border-bottom: 1px black solid;
            border-left: 1px black solid;
        }

        th:first-child {
            border-left: none;
        }

        tr:first-child {
            height: 50px;
        }

        td {
            border-left: 1px black solid;
            width: 20%;

            .tableRowContent {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                overflow-y: scroll;
                scrollbar-width: none; /* Firefox */
                -ms-overflow-style: none; /* Internet Explorer 10+ */

                .TicketContainer {
                    height: 100px;
                    min-height: 100px;
                    border: 2px #fc4f4f solid;
                    width: 95%;
                    border-radius: 10px;
                    background-color: rgb(252, 79, 79, 0.6);
                    box-shadow: rgb(0 0 0 / 30%) 2px 9px 13px 0px;
                    color: white;
                    text-align: center;
                    margin: 10px 0px 10px 0px;
                }
            }

            .tableRowContent::-webkit-scrollbar {
                width: 0;
                height: 0;
            }
        }

        td:first-child {
            border-left: none;
        }
    }

    .AddTicketButton {
        position: fixed;
        right: 30px;
        bottom: 30px;
        z-index: 200;
        height: 50px;
        width: 50px;
        background-color: green;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: rgb(0 0 0 / 30%) 2px 9px 8px 0px;
        cursor: pointer;
    }
}

.CreateTicketContent {
    height: 80%;
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        span {
            margin-right: 50px;
        }
    }
}

.EditTicketContent {
    margin-top: 30px;
    height: 90%;
    width: 90%;
    display: flex;
    flex-direction: row;

    .LeftSide {
        width: 50%;
        margin-right: 5%;

        .Dropdowns {
            display: flex;
            flex-direction: row;
            margin: 20px 0px 20px 0px;
            .Dropdown {
                margin-right: 20px;
                display: flex;
                align-items: center;
            }
        }

        .SummaryWrapper {
            border: black 2px solid;
            border-radius: 20px;
            padding: 10px;
            box-shadow: rgb(0 0 0 / 30%) 0px 4px 9px 0px;
            margin: 20px 0px 20px 0px;
        }

        .FILES_TODO_CONTENT {
            background-color: rgb(148, 200, 200);
            height: 200px;
        }
    }

    .RightSide {
        margin-left: 5%;
        width: 40%;
        display: flex;
        flex-direction: column;
    }
}

.LeaveCommentContainer {
    width: 100%;
    display: flex;
    align-items: center;
    .CommentInput {
        width: calc(100% - 40px);
        border-radius: 20px;
    }
    .SendButton {
        margin-left: 10px;
        height: 50px;
        cursor: pointer;
        border-radius: 50%;
        border: black solid 2px;
        padding: 5px;
    }
}

.PostsContainer {
    height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
    .PostContainer {
        background-color: var(--insideBackgroundColor);
        margin: 10px;
        width: 98%;
        border: 1px white solid;
        padding: 10px;
        box-shadow: rgb(0 0 0 / 30%) 9px 11px 13px 0px;
        height: fit-content;
    }

    .PostHeader {
        display: flex;
        flex-direction: row;
        min-height: 50px;
        height: 50px;
        margin-bottom: 10px;
        justify-content: space-between;

        .PostPicture {
            border-radius: 50%;
            border: 2px white solid;
            height: 90%;
            margin-right: 8px;
        }

        div {
            display: flex;
            flex-direction: row;
            div {
                display: flex;
                flex-direction: column;
                .name {
                    font-weight: 700;
                }
                .time {
                    font-size: smaller;
                }
            }
        }

        .ThreeDotsIcon {
            border-radius: 50%;
            height: 35px;
            &:hover {
                background-color: var(--backgroundColor);
            }
        }
    }
}
