@mixin unselectable() {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    cursor: default;
}

.ReservationContainer {
    @include unselectable();
    height: 100%;
    background-color: rgb(252, 79, 79, 0.6);
    border-color: #fc4f4f;
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    color: white;
    text-align: center;

    &:hover {
        background-color: hsl(268, 50%, 53%);
    }

    &:focus-within {
        background-color: hsl(264, 50%, 53%);
        outline: none;

        .DeleteIcon {
            display: initial;
            transform: translate(390%, -100%);
            width: 25%;
            padding: 5px;
            border: solid black 2px;
            border-radius: 50%;
            cursor: pointer;
            background-color: white;
        }
        .DeleteIcon_left {
            display: initial;
            transform: translate(-100%, -100%);
            width: 25%;
            padding: 5px;
            border: solid black 2px;
            border-radius: 50%;
            cursor: pointer;
            background-color: white;
        }
    }

    .BorderBottom,
    .BorderTop {
        height: 5px;
        cursor: row-resize;
    }

    .DeleteIcon {
        display: none;
    }
    .DeleteIcon_left {
        display: none;
    }
}
