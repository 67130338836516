// Phone
@media (max-width: 600px) {
    @keyframes contentOut {
        from {
            transform: translate(0px);
        }
        to {
            transform: translate(200px);
        }
    }

    @keyframes contentIn {
        from {
            transform: translate(200px);
        }
        to {
            transform: translate(0vw);
        }
    }

    .Syndicate_page_container {
        position: fixed;
        left: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        transform: translate(200px);

        &.open {
            animation-name: contentOut;
            animation-duration: 0.6s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.43, 0.43, 0.05, 0.99);
        }

        &.closed {
            animation-name: contentIn;
            animation-duration: 0.6s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.68, 0.16, 0.27, 1);
        }

        .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 90%;

            .icon {
                height: 40px;
                cursor: pointer;
            }
        }

        .subContainer {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 75vh;
            justify-content: center;
            align-items: center;

            &.unscrollable {
                overflow-y: hidden;
            }

            .options {
                height: 80%;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;

                &.clickable {
                    cursor: pointer;
                    &:hover {
                        background-color: lightgray;
                    }
                }

                .icon {
                    height: 50%;
                }

                .title {
                    display: flex;
                    flex-direction: column;
                    height: 30%;
                    img {
                        height: 50%;
                    }
                }

                .subOptions {
                    height: 45%;
                    min-height: 45%;
                    width: 80%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    &.small {
                        height: 30%;
                    }

                    .option {
                        width: 80%;
                        height: 100%;
                        justify-content: space-around;
                        align-items: center;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        cursor: pointer;
                        &:hover {
                            background-color: lightgray;
                        }

                        .icon {
                            height: 50%;
                        }
                    }
                }

                &.big {
                    height: 100%;
                    width: 80%;
                }

                &.small {
                    height: 50%;
                }

                &.navigation {
                    height: 80%;
                    width: 20%;

                    .icon {
                        width: 25%;
                    }
                }

                &.input {
                    height: 100%;
                    width: 80%;
                }
            }

            .mainOptions {
                height: 70vh;
                background-color: var(--backgroundColor);
            }
        }

        .inputContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            height: 60%;

            .inputSubContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 60%;
                height: 100%;

                input {
                    width: 100%;
                }
            }

            button {
                width: 15%;
            }
        }

        .separatorContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            margin-bottom: 20px;
            input {
                width: 20% !important;
            }
        }
    }

    .usersContainer {
        height: 38%;
        width: 80vw;
        display: flex;
        flex-direction: column;
    }

    .horizontalDivider {
        border-style: solid;
        width: 80%;
        height: 1px;
        border-top: 1px;
        margin: 5px;

        &.full {
            width: 100%;
        }
    }

    .verticalDivider {
        border-style: solid;
        height: 80%;
        width: 1px;
        border-left: 1px;
        margin: 5px;

        &.small {
            height: 60%;
        }
    }

    .individualIcon {
        height: 30px;
    }

    .inviteOptionsSlider {
        width: 90%;
        height: 100%;
    }

    .carousel-initialized {
        height: 90%;
    }

    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }

    .swiper-pagination-bullet {
        background-color: black;
        margin: -15px 4px;
    }
}

//PC
@media (min-width: 600px) {
    @keyframes scaleContentOut {
        from {
            transform: translate(0px);
            width: 100vw;
        }

        to {
            transform: translate(250px);
            width: calc(100vw - 250px);
        }
    }

    @keyframes scaleContentIn {
        from {
            transform: translate(250px);
            width: calc(100vw - 250px);
        }

        to {
            transform: translate(0px);
            width: 100vw;
        }
    }

    @keyframes OpenContent {
        from {
            height: 0%;
        }
        to {
            height: 40%;
        }
    }

    @keyframes CloseContent {
        from {
            height: 40%;
        }

        to {
            height: 0%;
        }
    }

    .Syndicate_page_container {
        position: fixed;
        left: 0px;
        bottom: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(100vw - 250px);
        transform: translate(250px);
        height: calc(100% - 50px);

        &.open {
            animation-name: scaleContentOut;
            animation-duration: 0.6s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.43, 0.43, 0.05, 0.99);
        }

        &.closed {
            animation-name: scaleContentIn;
            animation-duration: 0.6s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.68, 0.16, 0.27, 1);
        }

        .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 90%;
            height: 100px;

            .icon {
                height: 40px;
                cursor: pointer;
            }
        }

        .subContainer {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 80vh;
            justify-content: center;
            align-items: center;

            .options {
                height: 40%;
                width: 40%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;

                &.clickable {
                    cursor: pointer;

                    &:hover {
                        background-color: lightgray;
                    }
                }

                .icon {
                    height: 50%;
                }

                .title {
                    display: flex;
                    flex-direction: column;
                    height: 30%;

                    img {
                        height: 50%;
                    }
                }

                .subOptions {
                    width: 100%;
                    height: inherit;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .option {
                        width: 100%;
                        height: 100%;
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        cursor: pointer;

                        &:hover {
                            background-color: lightgray;
                        }

                        .icon {
                            height: 50%;
                        }
                    }
                }

                &.big {
                    height: 90%;
                    width: 80%;
                }

                &.navigation {
                    height: 80%;
                    width: 20%;

                    .icon {
                        width: 25%;
                    }
                }

                &.input {
                    height: 100%;
                    width: 80%;
                }
            }
        }

        .inputContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            height: 60%;

            .inputSubContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 60%;
                height: 100%;

                input {
                    width: 100%;
                }
            }

            button {
                width: 15%;
            }
        }

        .separatorContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            margin-bottom: 20px;

            input {
                width: 20% !important;
            }
        }

        .IndividualInviteContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            height: calc(100% - 200px);
            justify-content: center;
            align-items: center;
            padding-top: 20px;

            &.clickable {
                cursor: pointer;

                &:hover {
                    background-color: lightgray;
                }
            }

            .icon {
                height: 50%;
            }

            .title {
                display: flex;
                flex-direction: column;
                height: 30%;

                img {
                    height: 50%;
                }
            }

            .SubContainer {
                width: 100%;
                height: inherit;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .InputContainer {
                    width: 100%;
                    height: 70%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;

                    .AmenityListContainer {
                        width: 40%;
                        margin-right: 20px;
                        height: 80%;
                        overflow-y: auto;
                        overflow-x: hidden;
                        .Amenity {
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            .BtnsContainer {
                                display: flex;
                                flex-direction: row;
                                align-items: flex-end;
                                .AmenityBtn {
                                    height: 25px;
                                    margin-left: 10px;
                                    margin-right: 5px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    .CreateAmenityContainer {
                        width: 40%;
                        margin-left: 20px;

                        .CreateAmenityButton {
                            margin-top: 20px;
                        }
                    }

                    .BasicInputsContainer {
                        width: 90%;
                        display: flex;
                        flex-direction: row;
                        align-items: flex-end;
                        justify-content: space-evenly;

                        .form__group {
                            margin-bottom: 10px;
                        }
                    }

                    .CreateUnitLabeledArea {
                        width: 85%;
                        height: 40%;
                        display: flex;
                        align-content: center;
                        justify-content: center;

                        &.OpenContent {
                            animation-name: OpenContent;
                            animation-duration: 0.6s;
                            animation-fill-mode: forwards;
                            animation-timing-function: cubic-bezier(0.43, 0.43, 0.05, 0.99);
                        }

                        &.CloseContent {
                            animation-name: CloseContent;
                            animation-duration: 0.6s;
                            animation-fill-mode: forwards;
                            animation-timing-function: cubic-bezier(0.43, 0.43, 0.05, 0.99);
                        }

                        #CreateUnitContainer {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            justify-content: space-evenly;

                            .CreateUnitInputs {
                                width: 100%;
                                height: 50%;
                                display: flex;
                                flex-direction: row;
                                align-items: flex-end;
                                justify-content: space-evenly;
                            }

                            .CreateUnitButtons {
                                width: 100%;
                                height: 50%;
                                display: flex;
                                flex-direction: row;
                                align-items: flex-end;
                                justify-content: space-evenly;
                            }
                        }
                    }
                }
            }

            &.big {
                height: 90%;
                width: 80%;
            }

            &.navigation {
                height: 80%;
                width: 20%;

                .icon {
                    width: 25%;
                }
            }

            &.input {
                height: 100%;
                width: 80%;
            }
        }
    }

    .usersContainer {
        height: 70%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .horizontalDivider {
        border-style: solid;
        width: 80%;
        height: 1px;
        border-top: 1px;
        margin: 5px;

        &.full {
            width: 100%;
        }
    }

    .verticalDivider {
        border-style: solid;
        height: 80%;
        width: 1px;
        border-left: 1px;
        margin: 5px;

        &.small {
            height: 60%;
        }
    }

    .individualIcon {
        height: 30px;
    }

    .EditIcon {
        height: 20px;
        margin-right: 20px;
    }

    .DeleteIcon {
        height: 20px;
    }

    .EmptyIcon {
        height: 40px;
    }

    .MuiDataGrid-columnHeaderTitleContainerContent {
        padding-top: 10px;
    }
}
