.menu {
    position: relative;
    padding: 25px;
    background: #e8e8f3;
    border-radius: 100%;
    cursor: pointer;
    box-shadow: 7px 7px 15px rgba(55, 84, 170, 0.15), -7px -7px 20px rgba(255, 255, 255, 1),
        inset 0px 0px 4px rgba(255, 255, 255, 0.2), inset 7px 7px 15px rgba(55, 84, 170, 0),
        inset -7px -7px 20px rgba(255, 255, 255, 0), 0px 0px 4px rgba(255, 255, 255, 0);
    &::before,
    &::after {
        content: "";
        background: #c3c2c7;
        border-radius: 5px;
        width: 20px;
        height: 5px;
        position: absolute;
        left: 15px;
        top: 24px;
        transition: 0.2s ease;
        z-index: 1;
    }
    &::before {
        transform: rotate(0deg);
    }
    &::after {
        transform: rotate(-90deg);
    }
    &.open {
        opacity: 1;
        &::before {
            transform: rotate(45deg);
        }
        &::after {
            transform: rotate(-45deg);
        }
        .menuButton {
            opacity: 1;
            pointer-events: auto;
            &:first-of-type {
                bottom: 40px;
                right: 60px;
                height: 40px;
                width: 40px;
            }
            &:nth-of-type(2) {
                bottom: 60px;
                right: 5px;
                height: 40px;
                width: 40px;
                transition-delay: 0.05s;
            }
            &:last-of-type {
                bottom: 40px;
                right: -55px;
                height: 40px;
                width: 40px;
                transition-delay: 0.1s;
            }
        }
    }
}

.menuButton {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    border-radius: 50%;
    cursor: pointer;
    background: #e8e8f3;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 40px;
    width: 40px;
    opacity: 0;
    pointer-events: none;
    box-shadow: inherit;
    transition: 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28), 0.2s ease opacity,
        0.2s cubic-bezier(0.08, 0.82, 0.17, 1) transform;
    &:hover {
        transform: scale(1.1);
    }
}
