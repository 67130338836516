// Phone
// @media (max-width: 600px) {
//     .clockIcon {
//         height: 40px;
//     }

//     @mixin unselectable() {
//         -webkit-user-select: none;
//         -khtml-user-select: none;
//         -moz-user-select: none;
//         -ms-user-select: none;
//         -o-user-select: none;
//         user-select: none;
//         cursor: default;
//     }

//     .CalendarWeek {
//         display: flex;
//         justify-content: center;
//         align-content: center;
//         box-shadow: -7px 7px 10px 0px black;
//         border-radius: 10px;
//         .CalendarWeekContainer {
//             width: 100%;
//             height: 100%;
//             background-color: #ffffff;
//             border-radius: 10px;
//             .CalendarHeaderContainer {
//                 height: 5%;

//                 .tableHead {
//                     display: flex;
//                     flex-direction: row;
//                     .tableRow {
//                         display: flex;
//                         flex-direction: row;
//                         justify-content: space-evenly;
//                         align-content: stretch;
//                         align-items: center;
//                         flex-wrap: nowrap;
//                         width: 100%;

//                         .tableCell {
//                             width: 13.5%;
//                             text-align: center;
//                             @include unselectable;

//                             &:first-child {
//                                 width: 5.5%;
//                             }
//                         }
//                     }
//                 }
//             }
//             .CalendarBodyContainer {
//                 height: 90%;
//                 overflow-y: auto;
//                 display: flex;
//                 flex-direction: row;

//                 .tableTime {
//                     display: flex;
//                     flex-direction: column;
//                     width: 5.5%;

//                     .tableColumn {
//                         width: 100%;

//                         .tableCell {
//                             height: 30px;
//                             text-align: center;
//                             @include unselectable;

//                             &:first-child {
//                                 height: 15px;
//                                 border: none;
//                             }

//                             &:last-child {
//                                 height: 45px;
//                             }
//                         }
//                     }
//                 }

//                 .tableBody {
//                     display: flex;
//                     flex-direction: row;
//                     width: 94.5%;

//                     .tableColumn {
//                         width: 15%;

//                         .tableCell {
//                             height: 30px;
//                             border-bottom: 1px solid #767f93;
//                             border-right: 1px solid #767f93;

//                             &:hover > div {
//                                 height: 100%;
//                                 width: 100%;
//                                 .PlusIcon {
//                                     display: block;
//                                     animation-name: in;
//                                     animation-duration: 0.6s;
//                                 }
//                             }

//                             .PlusIcon {
//                                 height: 100%;
//                                 margin: 0 auto;
//                                 display: none;
//                             }

//                             &.shadowed {
//                                 background-color: #a7acb8;
//                             }

//                             &:first-child {
//                                 border: none;
//                                 border-bottom: 1px solid #767f93;
//                             }
//                         }

//                         &:first-child {
//                             .tableCell {
//                                 border-left: 1px solid #767f93;
//                                 &:first-child {
//                                     border-left: none;
//                                 }
//                             }
//                         }

//                         &:last-child {
//                             .tableCell {
//                                 border-right: none;
//                             }
//                         }
//                     }
//                 }
//             }

//             .NoLocationContainer {
//                 height: 100%;
//                 text-align: center;
//             }
//         }
//     }

//     table {
//         width: 100%;
//         height: 300px;
//         overflow-y: scroll;
//         th {
//             width: 13.5%;
//             text-align: center;
//         }
//         th:first-child {
//             width: 5.5%;
//         }
//         td {
//             text-align: center;
//             border-bottom: 2px solid #767f93;
//             border-right: 2px solid #767f93;
//             height: 27px;
//         }
//         td:last-child {
//             border-right: none;
//         }
//         .shadowed {
//             background-color: #a7acb8;
//         }
//     }

//     .TimeTable {
//         width: 5.5%;

//         td {
//             border-bottom: none;
//             border-right: none;
//         }
//         tr:first-child {
//             td {
//                 height: 12px;
//             }
//         }
//     }

//     .BodyTable {
//         width: 94.5%;

//         td {
//             width: 13.5%;
//         }

//         tr:first-child {
//             td {
//                 border-right: none;
//             }
//         }
//     }

//     @keyframes in {
//         from {
//             opacity: 0;
//         }
//         to {
//             opacity: 1;
//         }
//     }
// }

// //Tablet
// @media (min-width: 600px) and (max-width: 1080px) {
//     .clockIcon {
//         height: 40px;
//     }

//     @mixin unselectable() {
//         -webkit-user-select: none;
//         -khtml-user-select: none;
//         -moz-user-select: none;
//         -ms-user-select: none;
//         -o-user-select: none;
//         user-select: none;
//         cursor: default;
//     }

//     .CalendarWeek {
//         display: flex;
//         justify-content: center;
//         align-content: center;
//         box-shadow: -7px 7px 10px 0px black;
//         border-radius: 10px;
//         .CalendarWeekContainer {
//             width: 90%;
//             background-color: #ffffff;
//             border-radius: 10px;
//             .CalendarHeaderContainer {
//                 height: 5%;

//                 .tableHead {
//                     display: flex;
//                     flex-direction: row;
//                     .tableRow {
//                         display: flex;
//                         flex-direction: row;
//                         justify-content: space-evenly;
//                         align-content: stretch;
//                         align-items: center;
//                         flex-wrap: nowrap;
//                         width: 100%;

//                         .tableCell {
//                             width: 13.5%;
//                             text-align: center;
//                             @include unselectable;

//                             &:first-child {
//                                 width: 5.5%;
//                             }
//                         }
//                     }
//                 }
//             }
//             .CalendarBodyContainer {
//                 height: 90%;
//                 overflow-y: auto;
//                 display: flex;
//                 flex-direction: row;

//                 .tableTime {
//                     display: flex;
//                     flex-direction: column;
//                     width: 5.5%;

//                     .tableColumn {
//                         width: 100%;

//                         .tableCell {
//                             height: 30px;
//                             text-align: center;
//                             @include unselectable;

//                             &:first-child {
//                                 height: 15px;
//                                 border: none;
//                             }

//                             &:last-child {
//                                 height: 45px;
//                             }
//                         }
//                     }
//                 }

//                 .tableBody {
//                     display: flex;
//                     flex-direction: row;
//                     width: 94.5%;

//                     .tableColumn {
//                         width: 15%;

//                         .tableCell {
//                             height: 30px;
//                             border-bottom: 1px solid #767f93;
//                             border-right: 1px solid #767f93;

//                             &:hover > div {
//                                 height: 100%;
//                                 width: 100%;
//                                 .PlusIcon {
//                                     display: block;
//                                     animation-name: in;
//                                     animation-duration: 0.6s;
//                                 }
//                             }

//                             .PlusIcon {
//                                 height: 100%;
//                                 margin: 0 auto;
//                                 display: none;
//                             }

//                             &.shadowed {
//                                 background-color: #a7acb8;
//                             }

//                             &:first-child {
//                                 border: none;
//                                 border-bottom: 1px solid #767f93;
//                             }
//                         }

//                         &:first-child {
//                             .tableCell {
//                                 border-left: 1px solid #767f93;
//                                 &:first-child {
//                                     border-left: none;
//                                 }
//                             }
//                         }

//                         &:last-child {
//                             .tableCell {
//                                 border-right: none;
//                             }
//                         }
//                     }
//                 }
//             }

//             .NoLocationContainer {
//                 height: 100%;
//                 text-align: center;
//             }
//         }
//     }

//     table {
//         width: 100%;
//         height: 300px;
//         overflow-y: scroll;
//         th {
//             width: 13.5%;
//             text-align: center;
//         }
//         th:first-child {
//             width: 5.5%;
//         }
//         td {
//             text-align: center;
//             border-bottom: 2px solid #767f93;
//             border-right: 2px solid #767f93;
//             height: 27px;
//         }
//         td:last-child {
//             border-right: none;
//         }
//         .shadowed {
//             background-color: #a7acb8;
//         }
//     }

//     .TimeTable {
//         width: 5.5%;

//         td {
//             border-bottom: none;
//             border-right: none;
//         }
//         tr:first-child {
//             td {
//                 height: 12px;
//             }
//         }
//     }

//     .BodyTable {
//         width: 94.5%;

//         td {
//             width: 13.5%;
//         }

//         tr:first-child {
//             td {
//                 border-right: none;
//             }
//         }
//     }

//     @keyframes in {
//         from {
//             opacity: 0;
//         }
//         to {
//             opacity: 1;
//         }
//     }
// }

//PC
@media (min-width: 1080px) {
    .clockIcon {
        height: 35px;
    }

    @mixin unselectable() {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
        cursor: default;
    }

    .CalendarWeek {
        display: flex;
        justify-content: center;
        align-content: center;
        border-radius: 10px;
        border: black 1px solid;
        box-shadow: rgb(0 0 0 / 30%) 6px 7px 9px 0px;

        table {
            width: 100%;
            height: 300px;
            overflow-y: scroll;
            th {
                width: 13.5%;
                text-align: center;
            }
            th:first-child {
                width: 5.5%;
            }
            td {
                text-align: center;
                border-bottom: 2px solid #767f93;
                border-right: 2px solid #767f93;
                height: 27px;
            }
            td:last-child {
                border-right: none;
            }
            // .shadowed {
            //     background-color: #c9cfde;
            // }
        }

        .TimeTable {
            width: 5.5%;

            td {
                border-bottom: none;
                border-right: none;
            }
            tr:first-child {
                td {
                    height: 12px;
                }
            }
        }

        .BodyTable {
            width: 94.5%;

            td {
                width: 13.5%;
            }

            tr:first-child {
                td {
                    border-right: none;
                }
            }
        }

        .CalendarWeekContainer {
            width: 90%;
            background-color: #ffffff;
            border-radius: 10px;
            .CalendarHeaderContainer {
                height: 5%;
                padding-right: 10px;
                .tableHead {
                    display: flex;
                    flex-direction: row;
                    .tableRow {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-evenly;
                        align-content: stretch;
                        align-items: center;
                        flex-wrap: nowrap;
                        width: 100%;

                        .tableCell {
                            width: 13.5%;
                            text-align: center;
                            @include unselectable;

                            .cellWeekDay {
                                font-size: medium;
                            }

                            .cellDay {
                                font-weight: 500;
                                font-size: x-large;
                            }

                            &:first-child {
                                width: 5.5%;
                            }
                        }
                    }
                }
            }
            .CalendarBodyContainer {
                height: 90%;
                overflow-y: auto;
                display: flex;
                flex-direction: row;
                padding-right: 10px;

                &::-webkit-scrollbar {
                    width: 5px;
                    background-color: transparent;
                    cursor: pointer;
                }

                .tableTime {
                    display: flex;
                    flex-direction: column;
                    width: 5.5%;

                    .tableColumn {
                        width: 100%;

                        .tableCell {
                            height: 30px;
                            text-align: center;
                            @include unselectable;

                            &:first-child {
                                height: 15px;
                                border: none;
                            }

                            &:last-child {
                                height: 45px;
                            }
                        }
                    }
                }

                .tableBody {
                    display: flex;
                    flex-direction: row;
                    width: 94.5%;

                    .tableColumn {
                        width: 15%;

                        .tableCell {
                            height: 30px;
                            border-bottom: 1px solid #767f9362;
                            border-right: 1px solid #767f9362;

                            &:hover > div {
                                height: 100%;
                                width: 100%;
                                .PlusIcon {
                                    display: block;
                                    animation-name: in;
                                    animation-duration: 0.6s;
                                }
                            }

                            .PlusIcon {
                                height: 100%;
                                margin: 0 auto;
                                display: none;
                            }

                            // &.shadowed {
                            //     background-color: #e6ecff;
                            // }

                            &:first-child {
                                border: none;
                                border-bottom: 1px solid #767f9362;
                            }
                        }

                        &:first-child {
                            .tableCell {
                                border-left: 1px solid #767f9362;
                                &:first-child {
                                    border-left: none;
                                }
                            }
                        }

                        &:last-child {
                            .tableCell {
                                border-right: none;
                            }
                        }
                    }
                }
            }

            .scrolling {
                &::-webkit-scrollbar {
                    width: 5px;
                    cursor: pointer;
                }
                &::-webkit-scrollbar-track {
                    background-color: transparent;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: var(--borderColor);
                    border-radius: 20px;
                }
            }

            .NoLocationContainer {
                height: 100%;
                text-align: center;
            }
        }
    }

    @keyframes in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
