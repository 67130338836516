$primary: #696969;
$secondary: #BDBDBD;
$white: #fff;
$gray: grey;

.SelectorContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 30%;

    img {
        height: 80%;
        margin-right: 10px;
    }

    .Selector {
        border: none;
        border-bottom: black solid 2px;
        width:20vw;
    }
}

.SyndicatePic {
	flex-shrink: 0;
	margin: 4px;
	border: 2px solid white;
    border-radius: 50%;
}