#HelpIcon {
    width: 20px;
}
#SearchIcon {
    width: 20px;
}
.ProfileIcon {
    height: 80%;
}
.TopMenuLogo {
    height: 100%;
    cursor: pointer;
    transform: skew(33deg);
}

.headerContainer {
    display: flex;
    flex-direction: column;
    height: 50px;
    justify-content: space-around;
    align-items: center;
    box-shadow: rgb(0 0 0 / 30%) 9px 11px 13px 0px;

    .top {
        width: 100%;
        height: 0px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .subTop{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            width: 100%;    
        }
    }

    .bottom {
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: row;

        .hamburgerIcon {
                height: 40px;
                margin-top: 5px;
                margin-left: 20px;
                cursor: pointer;
            }

        .notColored {
            width: 20%;
            height: 100%;
            background-color: var(--borderColor);
            display: flex;
            flex-direction: row;
            .notColoredOrange {
                width: 50%;
                height: 100%;
                background-color: var(--borderColor);
            }
            .notColoredWhite {
                width: 50%;
                height: 100%;
                background-color: white;
                transform: skew(-33deg);
                display: flex;
                justify-content: center;
            }
        }
        .colored {
            display: flex;
            justify-content: flex-end;
            width: 80%;
            height: 100%;
            background-color: var(--borderColor);
        }
    }

    .navigationButtons {
        display: flex;
        flex-direction: row;
    }
    .ProfilePic {
        border-radius: 50%;
        border: 2px white solid;
        height: 90%;
        margin: 2px 40px 0px 40px;
    }
}
