$speed: 0.6s;
$delay: ($speed * 0.5);
$easing: cubic-bezier(0.55, 0, 0.1, 1);

/**
 * Overlay
 * -- only show for tablet and up
 */
@media only screen and (min-width: 40em) {
    .modal-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        background-color: rgba(#000, 0.75);
        opacity: 0;
        visibility: hidden;
        backface-visibility: hidden;
        transition: opacity $speed $easing, visibility $speed $easing;

        &.active {
            opacity: 1;
            visibility: visible;
        }
    }
} // media query

/**
 * Modal
 */
.modal-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    background-color: #fff;
    width: 600px;
    max-width: 75rem;
    min-height: 20rem;
    height: 25rem;
    padding: 1rem;
    border-radius: 3px;
    opacity: 0;
    overflow-y: auto;
    visibility: hidden;
    box-shadow: 0 2px 10px rgba(#000, 0.1);
    backface-visibility: hidden;
    transform: scale(1.2);
    transition: all $speed $easing;

    .close-modal {
        position: absolute;
        cursor: pointer;
        top: 5px;
        right: 15px;
        opacity: 0;
        backface-visibility: hidden;
        transition: opacity $speed $easing, transform $speed $easing;
        transition-delay: $delay;

        svg {
            width: 1.75em;
            height: 1.75em;
        }
    } // close modal

    .modal-content {
        opacity: 0;
        height: 70%;
        backface-visibility: hidden;
        transition: opacity $speed $easing;
        transition-delay: $delay;
    } // content

    &.active {
        visibility: visible;
        opacity: 1;
        transform: scale(1);

        .modal-content {
            opacity: 1;
        }

        .close-modal {
            transform: translateY(10px);
            opacity: 1;
        }
    }
}

.button-container{
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 10px;
    justify-content: space-around;
    width: 100%;
}

/**
 * Mobile styling
 */
@media only screen and (max-width: 39.9375em) {
    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-overflow-scrolling: touch;
        border-radius: 0;
        transform: scale(1.1);
        padding: 0 !important;
    }

    .close-modal {
        right: 20px !important;
    }
} // media query
