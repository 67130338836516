// Phone
@media (max-width: 600px) {
    .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        height: 80vh;

        .Your_syndicates_container {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 80%;
            width: 90%;
            overflow-y: scroll;
            border: black;
            border-style: solid;
            border-width: 2px;

            .condo_item_container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: left;
                width: 100%;
                cursor: pointer;

                &:hover {
                    background-color: lightblue;
                }

                .condo_desc_container {
                    display: flex;
                    flex-direction: column;
                }

                .icon_condo {
                    height: 60px;
                }
            }
        }

        .Create_syndicate_container {
            height: 60%;
            width: 90%;
            .Syndicate_button {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 100%;
                background-color: var(--highlightColor);

                .condo_add_container {
                    display: flex;
                    flex-direction: row;
                    height: 100%;
                }
            }
        }

        .Join_syndicate_container {
            height: 60%;
            width: 90%;
            .Syndicate_button {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 100%;
                background-color: var(--highlightColor);

                .condo_add_container {
                    display: flex;
                    flex-direction: row;
                    height: 100%;
                }
            }
        }

        .swiper {
            width: 100%;
            height: 100%;
        }

        .swiper-slide {
            text-align: center;
            font-size: 18px;
            background-color: var(--backgroundColor);

            /* Center slide text vertically */
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
        }

        .swiper-pagination-bullet {
            background-color: black;
            margin: -15px 4px;
        }
    }
}

//Tablet
@media (min-width: 600px) and (max-width: 1080px) {
    .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        height: 80vh;

        .subContent {
            display: flex;
            flex-direction: column;
            height: 70%;
            width: 30%;
            justify-content: space-between;
        }

        .Your_syndicates_container {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 70%;
            width: 40%;
            overflow-y: scroll;
            border: black;
            border-style: solid;
            border-width: 2px;

            .condo_item_container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: left;
                width: 100%;
                cursor: pointer;

                &:hover {
                    background-color: lightblue;
                }

                .condo_desc_container {
                    display: flex;
                    flex-direction: column;
                }

                .icon_condo {
                    height: 60px;
                }
            }
        }

        .Create_syndicate_container {
            height: 47%;
            width: 100%;
            .Syndicate_button {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 100%;
                background-color: var(--highlightColor);

                .condo_add_container {
                    display: flex;
                    flex-direction: row;
                    height: 100%;
                }
            }
        }

        .Join_syndicate_container {
            height: 47%;
            width: 100%;
            .Syndicate_button {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 100%;
                background-color: var(--highlightColor);

                .condo_add_container {
                    display: flex;
                    flex-direction: row;
                    height: 100%;
                }
            }
        }
    }
}

//PC
@media (min-width: 1080px) {

    @keyframes separatorHoverStart {
        from {
            transform: scaleY(1);
            width: 90%;
        }
        50%{
            transform: scaleY(1);
            width: 100%;
        }
        to {
            transform: scaleY(2);
            width: 100%;
        }
    }

    @keyframes HeaderHoverStart {
        from {
            font-size: 2vmin;
        }
        to {
            font-size: 2.2vmin;
        }
    }


    .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        height: 80vh;
        font-family: "Inconsolata", monospace;
        font-size: bold 900;

        .divider_solid {
            border-top: black solid 2px;
            width: 90%;
            height: 1px;
        }

        .subContent {
            display: flex;
            flex-direction: column;
            height: 70%;
            width: 30%;
            justify-content: space-between;
        }

        .Your_syndicates_container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            height: 70%;
            width: 40%;
            padding: 20px;
            background-color: var(----insideBackgroundColor);
            border: black solid 1px;
            box-shadow: 4px 4px 10px 0px rgba(0,0,0,30%);
            overflow-y: hidden;

            .condo_item_container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: left;
                width: 100%;
                cursor: pointer;

                &:hover {
                    background-color: lightblue;
                }

                .condo_desc_container {
                    display: flex;
                    flex-direction: column;
                }

                .icon_condo {
                    height: 60px;
                }
            }

            &:hover{
                h3{
                    animation-name: HeaderHoverStart;
                    animation-duration: 0.6s;
                    animation-fill-mode: forwards;
                    animation-timing-function: cubic-bezier(0.43, 0.43, 0.05, 0.99);
                }
                hr{
                    border-color: var(--borderColor);
                    animation-name: separatorHoverStart;
                    animation-duration: 0.6s;
                    animation-fill-mode: forwards;
                    animation-timing-function: cubic-bezier(0.43, 0.43, 0.05, 0.99);
                }
            }
        }

        .SyndicateButtonContainer {
            height: 47%;
            width: 100%;
            overflow: hidden;
            box-shadow: 4px 4px 10px 0px rgba(0,0,0,30%);
            border: black solid 1px;
            padding: 5px;
            .Syndicate_button {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border: none;
                width: 100%;
                height: 100%;
                background-color: var(----insideBackgroundColor);

                h3{
                    font-size: 2vmin;
                }

                .syndicateIcon {
                    width: 90vmin;
                    height: 90vmin;
                    min-height: 100px;
                }

                &:hover{
                    h3{
                        animation-name: HeaderHoverStart;
                        animation-duration: 0.6s;
                        animation-fill-mode: forwards;
                        animation-timing-function: cubic-bezier(0.43, 0.43, 0.05, 0.99);
                    }
                    hr{
                        border-color: var(--borderColor);
                        animation-name: separatorHoverStart;
                        animation-duration: 0.6s;
                        animation-fill-mode: forwards;
                        animation-timing-function: cubic-bezier(0.43, 0.43, 0.05, 0.99);
                    }
                }
            }
        }
    }
}
