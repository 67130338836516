// Phone
@media (max-width: 600px) {
    #TopMenuContainer {
        display: flex;
        border-bottom-style: outset;
        border-bottom-color: black;
        border-bottom-width: 5px;
        width: 100vw;
        #TopMenuContent {
            width: 100%;
            height: 15vh;
            .TopMenuLogo {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    #BodyContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        #AppDesc {
            height: 250px;
        }
    }

    .loginForm {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 90%;
        max-width: 380px;
        margin-bottom: 20px;
        .InputContainers {
            width: 100%;
        }
        .loginButton {
            margin-top: 20px;
        }
    }

    #SignUpContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        max-width: 380px;
        margin-bottom: 20px;
        text-align: center;
    }

    #SignUpContainer:after {
        content: "";
        display: inline-block;
        width: 100%;
    }
}

//Tablet
@media (min-width: 600px) and (max-width: 1080px) {
    #TopMenuContainer {
        display: flex;
        border-bottom-style: outset;
        border-bottom-color: black;
        border-bottom-width: 5px;
        width: 100vw;
        #TopMenuContent {
            width: 100%;
            height: 15vh;
            .TopMenuLogo {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    #BodyContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        #AppDesc {
            height: 250px;
        }
    }

    .loginForm {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 90%;
        max-width: 380px;
        margin-bottom: 20px;
        .InputContainers {
            width: 100%;
        }
        .loginButton {
            margin-top: 20px;
        }
    }

    #SignUpContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        max-width: 380px;
        margin-bottom: 20px;
        text-align: center;
    }

    #SignUpContainer:after {
        content: "";
        display: inline-block;
        width: 100%;
    }
}

// PC
@media (min-width: 1080px) {
.LoginHeaderContainer {
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: space-around;
    align-items: center;
    box-shadow: rgb(0 0 0 / 30%) 9px 11px 13px 0px;

    .top {
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .bottom {
        width: 100%;
        height: 50px;
        display: flex;

        flex-direction: row;

        .notColored {
            width: 20%;
            height: 100%;
            background-color: var(--borderColor);
            display: flex;
            flex-direction: row;

            .notColoredOrange {
                width: 50%;
                height: 100%;
                background-color: var(--borderColor);
            }

            .notColoredWhite {
                width: 50%;
                height: 100%;
                background-color: white;
                transform: skew(-33deg);
                display: flex;
                justify-content: center;
            }
        }

        .colored {
            width: 80%;
            height: 100%;
            background-color: var(--borderColor);
        }
    }

    .navigationButtons {
        display: flex;
        flex-direction: row;
    }
}

    #BodyContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .LoginForm {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 40%;

            .ForgotPasswordText{
                font-size: x-small;
                width: 70%;
                margin: 20px;
                cursor: pointer;
            }
        
            .InputContainers {
                width: 70%;
            }
        
            .loginButton {
                margin: 20px 0px 0px 20px;
            }
        }

        .SignUpContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 90%;
            max-width: 380px;
            margin: 40px 0 20px 0;
            text-align: center;
        }

        .MontrealBackdrop{
            z-index: -100;
            position: fixed;
            bottom: 0px;
            width: 100%;
        }
    }
}
