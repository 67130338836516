.DragDropContainer{
    border: black 1px solid;
    display: 'inline-block'; 
    position: 'relative';
    box-shadow: rgb(0 0 0 / 30%) 7px 7px 13px 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.dragging{
        border-color: var(--borderColor);
    }

    .DragDropDroppingBorder{
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .DragDropEmptyContainer{
        text-align: center;
    }

    .DragDropContent{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .DragDropFilesContent{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            overflow: auto;
            .FileRow {
                    width: 80%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    border-bottom: black solid 1px;
                    margin-bottom: 10px;
            
                    .FileDeleteIcon {
                        max-height: 20px;
                        cursor: pointer;
                    }
                }
        }
        
    }
}