$primary: #696969;
$secondary: #bdbdbd;
$white: #fff;
$gray: grey;
$error: #9c000d;
.form__group {
    position: relative;
    padding: 15px 0 0;
    margin-top: 10px;
    width: 50%;
}

.form__group__large {
    position: relative;
    padding: 15px 0 0;
    margin-top: 10px;
    width: 90%;
}

.form__group__small {
    position: relative;
    padding: 15px 0 0;
    margin-top: 10px;
    width: 25%;
}

.form__field {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid $gray;
    outline: 0;
    font-size: 1.3rem;
    color: black;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;

    &::placeholder {
        color: transparent;
    }

    &:placeholder-shown ~ .form__label {
        font-size: 1.3rem;
        cursor: text;
        top: 20px;
    }
}

.form__field_error {
    border-bottom: 2px solid $error;
}

.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: $gray;
}

.form__field:hover {
    ~ .form__label {
        color: $primary;
        font-weight: 700;
    }
}

.form__field:focus {
    ~ .form__label {
        position: absolute;
        top: 0;
        display: block;
        transition: 0.2s;
        font-size: 1rem;
        color: $primary;
        font-weight: 700;
    }
    padding-bottom: 6px;
    font-weight: 700;
    border-width: 3px;
    border-image: linear-gradient(to right, $primary, $secondary);
    border-image-slice: 1;
}
/* reset input */
.form__field {
    &:required,
    &:invalid {
        box-shadow: none;
    }
}

.showPasswordButton {
    width: 20px;
    position: absolute;
    right: 0px;
    height: 100%;
    cursor: pointer;
}
