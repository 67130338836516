.promiseBtn {
    color: black;
    transition: all 0.3s;
    position: relative;
    line-height: 50px;
    min-height: 50px;
    text-align: center;
    min-width: 100px;
    cursor: pointer;
  }
  .promiseBtn span {
    transition: all 0.3s;
  }
  .promiseBtn::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: var(--borderColor);
    border-bottom-color: var(--borderColor);
    transform: scale(0.1, 1);
  }
  .promiseBtn:hover span {
    letter-spacing: 2px;
  }
  .promiseBtn:hover::before {
    opacity: 1; 
    transform: scale(1, 1); 
  }
  .promiseBtn::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: all 0.3s;
    background-color: var(--backgroundColor);
  }
  .promiseBtn:hover::after {
    opacity: 0; 
    transform: scale(0.1, 1);
  }