.recurringEventContainer {
    height: 30vh;
    overflow: auto;
    .recurringEventRow {
        height: 20%;
        display: flex;
        flex-direction: row;
        .imgContainer {
            height: 100%;
            .DeleteIcon {
                height: 80%;
                cursor: pointer;
            }
        }
    }
}
