.AdminContainer {
    height: 20%;
    border-bottom: 2px solid black;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .emptyItem + div {
        height: 100%;
        display: flex;
        align-items: center;
        .ProfilePic {
            border-radius: 50%;
            border: 2px black solid;
            height: 80%;
            margin-right: 20px;
        }
    }

    .DeleteIcon {
        height: 70%;
        cursor: pointer;
    }
}
