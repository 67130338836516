// Phone
@media (max-width: 600px) {
    @keyframes drawerOut {
        0% {
            transform: translate(0px);
        }
        100% {
            transform: translate(-200px);
        }
    }

    @keyframes drawerIn {
        0% {
            transform: translate(-200px);
        }
        100% {
            transform: translate(0vw);
        }
    }

    .navigationContainer {
        width: 200px;
        z-index: 1;

        &.open {
            animation-name: drawerIn;
            animation-duration: 0.6s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.43, 0.43, 0.05, 0.99);
        }

        &.closed {
            animation-name: drawerOut;
            animation-duration: 0.6s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.68, 0.16, 0.27, 1);
        }

        .navigationSubContainer {
            width: 200px;
            height: 100%;
            border-width: 0px;
            border-right: 2px;
            border-style: solid;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .navigationButtons {
                display: flex;
                flex-direction: column;
                height: 40%;

                .bt {
                    margin-top: 5px;
                    height: inherit;
                }
            }

            .otherButtons {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                .icon {
                    width: 100px;
                    cursor: pointer;
                }
            }
        }
    }

    .drawerButton {
        position: fixed;
        bottom: 0px;
        left: 200px;
        border-top: black solid 2px;
        border-right: black solid 2px;
        z-index: 200;

        cursor: pointer;
        .icon {
            width: 50px;
        }
    }
}

//Tablet
@media (min-width: 600px) and (max-width: 1080px) {
    @keyframes drawerOut {
        0% {
            transform: translate(0px);
        }
        100% {
            transform: translate(-20vw);
        }
    }

    @keyframes drawerIn {
        0% {
            transform: translate(-20vw);
        }
        100% {
            transform: translate(0vw);
        }
    }

    .navigationContainer {
        width: 20vw;
        min-width: 200px;
        z-index: 1;

        &.open {
            animation-name: drawerIn;
            animation-duration: 0.6s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.43, 0.43, 0.05, 0.99);
        }

        &.closed {
            animation-name: drawerOut;
            animation-duration: 0.6s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.68, 0.16, 0.27, 1);
        }

        .navigationSubContainer {
            width: 20vw;
            min-width: 200px;
            height: 100%;
            border-width: 0px;
            border-right: 2px;
            border-style: solid;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .navigationButtons {
                display: flex;
                flex-direction: column;
                height: 40%;

                .bt {
                    margin-top: 5px;
                    height: inherit;
                }
            }

            .otherButtons {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                .icon {
                    width: 100px;
                    cursor: pointer;
                }
            }
        }
    }

    .drawerButton {
        position: fixed;
        bottom: 0px;
        left: max(20vw, 200px);
        border-top: black solid 2px;
        border-right: black solid 2px;
        z-index: 200;

        cursor: pointer;
        .icon {
            width: 50px;
        }
    }
}

//PC
@media (min-width: 1080px) {
    @keyframes drawerOut {
        0% {
            transform: translate(0px);
        }
        100% {
            transform: translate(-250px);
        }
    }

    @keyframes drawerIn {
        0% {
            transform: translate(-250px);
        }
        100% {
            transform: translate(0vw);
        }
    }

    .navigationContainer {
        width: var(--navigatorWidth);
        min-width: 200px;
        z-index: 1;

        &.open {
            animation-name: drawerIn;
            animation-duration: 0.6s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.43, 0.43, 0.05, 0.99);
            .drawerButton {
                top: 0px;
            }
        }

        &.closed {
            animation-name: drawerOut;
            animation-duration: 0.6s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.68, 0.16, 0.27, 1);
            .drawerButton {
                top: 0px;
            }
        }

        .navigationSubContainer {
            width: var(--navigatorWidth);
            min-width: 200px;
            height: 100%;
            border-width: 0px;
            border-right: 2px;
            border-style: solid;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .navigationButtons {
                display: flex;
                flex-direction: column;
                height: 40%;

                .bt {
                    margin-top: 5px;
                    height: inherit;
                }
            }

            .otherButtons {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                height: 64px;
                .icon {
                    height: 100%;
                    cursor: pointer;
                }
            }
        }
    }

    .drawerButton {
        position: fixed;
        top: 100px;
        left: max(21vw, 200px);

        cursor: pointer;
        .icon {
            width: 50px;
            height: 64px;
            z-index: 200;
        }
    }

    .hoverArea {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 50px;
        height: 85vh;
        z-index: 50;
    }
}
