.file__upload__input {
    display: none;
}

.file__upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;

    .file__upload__icon {
        height: 30px;
        margin-right: 10px;
    }
}

.slider {
    width: 50%;
    margin: 10px 30px 30px 30px;
}

.image__editor {
    margin-top: 20px;
}
