.Container {
    display: flex;
    flex-direction: column;
    width: 79%;
    height: 100%;
    .amenityContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 30%;
        .createAmenityContainer {
            display: flex;
            align-items: center;
            flex-direction: column;
            align-content: center;
            justify-content: flex-start;
            width: 40%;
            height: 100%;
        }
        .amenityListContainer {
            width: 60%;
            height: 100%;
            .amenity {
                height: 10%;
                display: flex;
                flex-direction: row;
                .imgContainer {
                    height: 100%;
                    .DeleteIcon {
                        height: 80%;
                        cursor: pointer;
                    }
                }
            }

            .selected {
                background-color: gray;
            }
        }
    }
}
