// Phone
@media (max-width: 600px) {
    .ForumContainer {
        display: flex;
        overflow-y: auto;
        flex-direction: column;
        align-items: center;
        width: 100vw;
        height: 89%;
        position: fixed;
        left: 0px;
        transform: translate(20vw);

        @keyframes contentOut {
            from {
                transform: translate(0px);
            }
            to {
                transform: translate(20vw);
            }
        }

        @keyframes contentIn {
            from {
                transform: translate(20vw);
            }
            to {
                transform: translate(0vw);
            }
        }

        .ForumPostContainer {
            background-color: var(--insideBackgroundColor);
            border-radius: 10px;
            box-shadow: 5px;
            margin: 10px;
            width: 50%;
            border: 1px var(--borderColor) solid;
            padding: 10px;
        }

        .ForumPostHeader {
            display: flex;
            flex-direction: row;
            min-height: 50px;
            height: 50px;
            margin-bottom: 10px;
            justify-content: space-between;

            div {
                display: flex;
                flex-direction: row;
                div {
                    display: flex;
                    flex-direction: column;
                    .name {
                        font-weight: 700;
                    }
                    .time {
                        font-size: smaller;
                    }
                }
            }

            .ThreeDotsIcon {
                &:hover {
                    background-color: var(--insideBackgroundColor);
                }
            }
        }

        .ForumPostInteractions {
            height: 50px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .CommentInteraction {
                height: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                &:hover {
                    background-color: var(--highlightColor);
                }

                .CommentIcon {
                    height: 80%;
                }
            }
        }

        .ForumPostComments {
            .ForumPostCommentContainer {
                .ForumPostComment {
                    display: flex;
                    flex-direction: row;
                    .ForumPostCommentText {
                        background-color: var(--highlightColor);
                        border-radius: 10px;
                        padding: 10px;
                        .name {
                            font-weight: 700;
                        }
                    }
                    .time {
                        font-size: smaller;
                    }
                }
                .PostPicture {
                    height: 32px;
                }
            }

            .ForumPostCommentAnswerContainer {
                height: auto;
                display: flex;
                align-items: flex-start;
                .ForumPostCommentAnswer {
                    width: 90%;
                    min-height: 68px;
                    border-radius: 20px;
                    padding: 10px;
                    border: 1px var(--borderColor) solid;
                    white-space: pre-wrap;
                    overflow: hidden;

                    &:focus {
                        border: 1px var(--borderColor) solid;
                    }
                }
            }
        }

        &.open {
            animation-name: contentOut;
            animation-duration: 0.6s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.43, 0.43, 0.05, 0.99);
        }

        &.closed {
            animation-name: contentIn;
            animation-duration: 0.6s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.68, 0.16, 0.27, 1);
        }

        .PostPicture {
            border-radius: 50%;
            border: 2px black solid;
            height: 32px;
            margin-right: 10px;
        }

        .horizontalDivider {
            border-style: solid;
            width: 100%;
            height: 1px;
            border-top: 1px;
            margin: 5px;

            &.full {
                width: 100%;
            }
        }
    }
}

//Tablet
@media (min-width: 600px) and (max-width: 1080px) {
    .ForumContainer {
        display: flex;
        overflow-y: auto;
        flex-direction: column;
        align-items: center;
        width: 100vw;
        height: 89%;
        position: fixed;
        left: 0px;
        transform: translate(20vw);

        @keyframes contentOut {
            from {
                transform: translate(0px);
            }
            to {
                transform: translate(20vw);
            }
        }

        @keyframes contentIn {
            from {
                transform: translate(20vw);
            }
            to {
                transform: translate(0vw);
            }
        }

        .ForumPostContainer {
            background-color: var(--insideBackgroundColor);
            border-radius: 10px;
            box-shadow: 5px;
            margin: 10px;
            width: 50%;
            border: 1px var(--borderColor) solid;
            padding: 10px;
        }

        .ForumPostHeader {
            display: flex;
            flex-direction: row;
            min-height: 50px;
            height: 50px;
            margin-bottom: 10px;
            justify-content: space-between;

            div {
                display: flex;
                flex-direction: row;
                div {
                    display: flex;
                    flex-direction: column;
                    .name {
                        font-weight: 700;
                    }
                    .time {
                        font-size: smaller;
                    }
                }
            }

            .ThreeDotsIcon {
                &:hover {
                    background-color: var(--insideBackgroundColor);
                }
            }
        }

        .ForumPostInteractions {
            height: 50px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .CommentInteraction {
                height: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                &:hover {
                    background-color: var(--highlightColor);
                }

                .CommentIcon {
                    height: 80%;
                }
            }
        }

        .ForumPostComments {
            .ForumPostCommentContainer {
                .ForumPostComment {
                    display: flex;
                    flex-direction: row;
                    .ForumPostCommentText {
                        background-color: var(--highlightColor);
                        border-radius: 10px;
                        padding: 10px;
                        .name {
                            font-weight: 700;
                        }
                    }
                    .time {
                        font-size: smaller;
                    }
                }
                .PostPicture {
                    height: 32px;
                }
            }

            .ForumPostCommentAnswerContainer {
                height: auto;
                display: flex;
                align-items: flex-start;
                .ForumPostCommentAnswer {
                    width: 90%;
                    min-height: 68px;
                    border-radius: 20px;
                    padding: 10px;
                    border: 1px var(--borderColor) solid;
                    white-space: pre-wrap;
                    overflow: hidden;

                    &:focus {
                        border: 1px var(--borderColor) solid;
                    }
                }
            }
        }

        &.open {
            animation-name: contentOut;
            animation-duration: 0.6s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.43, 0.43, 0.05, 0.99);
        }

        &.closed {
            animation-name: contentIn;
            animation-duration: 0.6s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.68, 0.16, 0.27, 1);
        }

        .PostPicture {
            border-radius: 50%;
            border: 2px black solid;
            height: 32px;
            margin-right: 10px;
        }

        .horizontalDivider {
            border-style: solid;
            width: 100%;
            height: 1px;
            border-top: 1px;
            margin: 5px;

            &.full {
                width: 100%;
            }
        }
    }
}

//PC
@media (min-width: 1080px) {
    .ForumContainer {
        position: fixed;
        left: 0px;
        top: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: auto !important;
        width: 75vw;
        transform: translate(20vw);
        height: 89%;
        background-color: var(--backgroundColor);
        padding-bottom: 60px;

        &::-webkit-scrollbar {
            display: block;
            width: 50px;
        }

        &.open {
            animation-name: scaleContentOut;
            animation-duration: 0.6s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.43, 0.43, 0.05, 0.99);
        }

        &.closed {
            animation-name: scaleContentIn;
            animation-duration: 0.6s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.68, 0.16, 0.27, 1);
        }

        .ForumHeaderContainer {
            display: flex;
            flex-direction: row;
            margin-top: 20px;
            align-items: center;
            height: 64px;
            max-height: 64px;
            .FocumIcon {
                height: 64px;
                margin-right: 10px;
            }
        }

        .ForumPostContainer {
            background-color: var(--insideBackgroundColor);
            margin: 10px;
            width: 50%;
            border: 1px white solid;
            padding: 10px;
            box-shadow: rgb(0 0 0 / 30%) 9px 11px 13px 0px;
            height: 600px;
        }

        .ForumPostHeader {
            display: flex;
            flex-direction: row;
            min-height: 50px;
            height: 50px;
            margin-bottom: 10px;
            justify-content: space-between;

            div {
                display: flex;
                flex-direction: row;
                div {
                    display: flex;
                    flex-direction: column;
                    .name {
                        font-weight: 700;
                    }
                    .time {
                        font-size: smaller;
                    }
                }
            }

            .ThreeDotsIcon {
                border-radius: 50%;
                height: 35px;
                &:hover {
                    background-color: var(--backgroundColor);
                }
            }
        }

        .ForumPostInteractions {
            height: 50px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .CommentInteraction {
                height: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                &:hover {
                    background-color: var(--highlightColor);
                }

                .CommentIcon {
                    height: 80%;
                }
            }
        }

        .ForumPostComments {
            .ForumPostCommentContainer {
                .ForumPostComment {
                    display: flex;
                    flex-direction: row;
                    .ForumPostCommentText {
                        background-color: var(--highlightColor);
                        border-radius: 10px;
                        padding: 10px;
                        .name {
                            font-weight: 700;
                        }
                    }
                    .time {
                        font-size: smaller;
                    }
                }
                .PostPicture {
                    height: 32px;
                }
            }

            .ForumPostCommentAnswerContainer {
                height: auto;
                display: flex;
                align-items: flex-start;
                .ForumPostCommentAnswer {
                    width: 90%;
                    min-height: 68px;
                    border-radius: 20px;
                    padding: 10px;
                    border: 1px var(--highlightColor) solid;
                    white-space: pre-wrap;
                    overflow: hidden;
                    background-color: var(--highlightColor);
                    resize: none;

                    &:focus {
                        border: 1px var(--borderColor) solid;
                    }
                }
            }
        }

        .PostPicture {
            border-radius: 50%;
            border: 2px black solid;
            height: 32px;
            margin-right: 10px;
        }

        .horizontalDivider {
            border-style: solid;
            width: 100%;
            height: 1px;
            border-top: 1px;
            margin: 5px;

            &.full {
                width: 100%;
            }
        }
    }

    .ForumContainer::-webkit-scrollbar {
        display: none;
    }
}
