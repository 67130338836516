// Phone
@media (max-width: 600px) {
    #FormBodyContainer {
        left: 0;
        right: 0;
        z-index: 1;
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        #FormTitle {
            background-color: silver;
            width: 487px;
            padding: 10px 0px 10px 30px;
        }

        #FormContainer {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin-top: 100px;
            // min-height: 600px;

            .infoContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: start;
                width: 100%;
                text-align: center;
                min-height: 350px;

                .emailList {
                    width: 100%;
                    overflow-y: auto;
                    padding-right: 20px;
                }
            }

            .oneButton {
                display: flex;
                justify-content: flex-start;
                width: 100%;

                &.right {
                    justify-content: flex-end;
                }
            }

            .twoButton {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }

            .threeButton {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }

            .contentContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
        // .form-control_error {
        //     @extend .form-control;
        //     border-color: red;
        // }

        .h1-header {
            font-weight: 100;
            margin-top: 0;
            font-size: 36px;
            background: silver;
            padding: 25px;
            margin-left: -10px;
            width: 425px;
            position: absolute;
        }
        @media (max-width: 480px) {
            .h1-header {
                position: static;
                font-weight: 100;
                margin-top: 0;
                font-size: 18px;
                background: transparent;
                padding: 25px;
                margin-left: -10px;
                /* width: 400px; */
                border-bottom: 1px solid white;
            }
        }
        @media only screen and (min-device-width: 320px) and (max-device-width: 736px) and (orientation: landscape) {
            .h1-header {
                position: static;
                font-weight: 100;
                margin-top: 0;
                font-size: 18px;
                background: transparent;
                padding: 0 0 10px 20px;
                margin-left: -10px;
                border-bottom: 1px solid white;
            }
        }

        .btn-default,
        .btn-default:hover,
        .btn-default:focus {
            color: #333;
            text-shadow: none; /* Prevent inheritence from `body` */
            background-color: #fff;
            border: 1px solid #fff;
        }
        .btn-lg {
            border-radius: 0;
            padding: 10px 20px;
            font-size: 20px;
        }
        .btn-default,
        .btn-default:hover,
        .btn-default:focus {
            color: #fff;
            text-shadow: none;
            background-color: #2ecc71;
            border: none;
        }
    }

    .progress {
        border-radius: 0;
        margin-bottom: 0;
        height: 7px;
        background-color: #1e6697;
        -webkit-box-shadow: none;
        box-shadow: none;

        .progress-bar {
            background-color: #2ecc71;
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }

    .hide {
        display: none;
    }

    .errorMessage {
        color: red;
    }

    .clickableText {
        background: none;
        border: none;
        &:hover {
            cursor: pointer;
        }
    }
}

//Tablet  600 to 1080
@media (min-width: 0px) and (max-width: 0px) {
    #FormBodyContainer {
        left: 0;
        right: 0;
        z-index: 1;
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        #FormTitle {
            background-color: silver;
            width: 487px;
            padding: 10px 0px 10px 30px;
        }

        #FormContainer {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin-top: 100px;
            height: 90vh;

            .infoContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: start;
                width: 30vw;
                height: 50vh;

                .emailList {
                    width: 100%;
                    overflow-y: auto;
                    padding-right: 20px;
                }
            }

            .oneButton {
                display: flex;
                justify-content: flex-start;
                width: 100%;

                &.right {
                    justify-content: flex-end;
                }
            }

            .twoButton {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }

            .threeButton {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }

            .contentContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
        // .form-control_error {
        //     @extend .form-control;
        //     border-color: red;
        // }

        .h1-header {
            font-weight: 100;
            margin-top: 0;
            font-size: 36px;
            background: silver;
            padding: 25px;
            margin-left: -10px;
            width: 425px;
            position: absolute;
        }
        @media (max-width: 480px) {
            .h1-header {
                position: static;
                font-weight: 100;
                margin-top: 0;
                font-size: 18px;
                background: transparent;
                padding: 25px;
                margin-left: -10px;
                /* width: 400px; */
                border-bottom: 1px solid white;
            }
        }
        @media only screen and (min-device-width: 320px) and (max-device-width: 736px) and (orientation: landscape) {
            .h1-header {
                position: static;
                font-weight: 100;
                margin-top: 0;
                font-size: 18px;
                background: transparent;
                padding: 0 0 10px 20px;
                margin-left: -10px;
                border-bottom: 1px solid white;
            }
        }

        .btn-default,
        .btn-default:hover,
        .btn-default:focus {
            color: #333;
            text-shadow: none; /* Prevent inheritence from `body` */
            background-color: #fff;
            border: 1px solid #fff;
        }
        .btn-lg {
            border-radius: 0;
            padding: 10px 20px;
            font-size: 20px;
        }
        .btn-default,
        .btn-default:hover,
        .btn-default:focus {
            color: #fff;
            text-shadow: none;
            background-color: #2ecc71;
            border: none;
        }
    }

    .progress {
        border-radius: 0;
        margin-bottom: 0;
        height: 7px;
        background-color: #1e6697;
        -webkit-box-shadow: none;
        box-shadow: none;

        .progress-bar {
            background-color: #2ecc71;
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }

    .hide {
        display: none;
    }

    .errorMessage {
        color: red;
    }

    .clickableText {
        background: none;
        border: none;
        &:hover {
            cursor: pointer;
        }
    }
}

//PC  TODO change to 1080 for tablet ?
@media (min-width: 600px) {
    #FormBodyContainer {
        left: 0;
        right: 0;
        z-index: 1;
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        #FormTitle {
            background-color: silver;
            width: 487px;
            padding: 10px 0px 10px 30px;
        }

        #FormContainer {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin-top: 100px;
            height: 90vh;

            .contentContainer{
                height: 80%;
            }

            .infoContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: start;
                width: 30vw;
                height: 500px;

                .emailList {
                    width: 100%;
                    overflow-y: auto;
                    padding-right: 20px;
                }
            }

            .oneButton {
                display: flex;
                justify-content: flex-start;
                width: 100%;
                height: 50px;

                &.right {
                    justify-content: flex-end;
                }
            }

            .twoButton {
                display: flex;
                justify-content: space-between;
                width: 100%;
                height: 50px;
            }

            .threeButton {
                display: flex;
                justify-content: space-between;
                width: 100%;
                height: 50px;
            }

            .contentContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 500px;
            }
        }
        // .form-control_error {
        //     @extend .form-control;
        //     border-color: red;
        // }

        .h1-header {
            font-weight: 100;
            margin-top: 0;
            font-size: 36px;
            background: silver;
            padding: 25px;
            margin-left: -10px;
            width: 425px;
            position: absolute;
        }
        @media (max-width: 480px) {
            .h1-header {
                position: static;
                font-weight: 100;
                margin-top: 0;
                font-size: 18px;
                background: transparent;
                padding: 25px;
                margin-left: -10px;
                /* width: 400px; */
                border-bottom: 1px solid white;
            }
        }
        @media only screen and (min-device-width: 320px) and (max-device-width: 736px) and (orientation: landscape) {
            .h1-header {
                position: static;
                font-weight: 100;
                margin-top: 0;
                font-size: 18px;
                background: transparent;
                padding: 0 0 10px 20px;
                margin-left: -10px;
                border-bottom: 1px solid white;
            }
        }

        .btn-default,
        .btn-default:hover,
        .btn-default:focus {
            color: #333;
            text-shadow: none; /* Prevent inheritence from `body` */
            background-color: #fff;
            border: 1px solid #fff;
        }
        .btn-lg {
            border-radius: 0;
            padding: 10px 20px;
            font-size: 20px;
        }
        .btn-default,
        .btn-default:hover,
        .btn-default:focus {
            color: #fff;
            text-shadow: none;
            background-color: #2ecc71;
            border: none;
        }
    }

    .progress {
        border-radius: 0;
        margin-bottom: 0;
        height: 7px;
        background-color: #1e6697;
        -webkit-box-shadow: none;
        box-shadow: none;

        .progress-bar {
            background-color: #2ecc71;
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }

    .hide {
        display: none;
    }

    .errorMessage {
        color: red;
    }

    .clickableText {
        background: none;
        border: none;
        &:hover {
            cursor: pointer;
        }
    }
}
