:root {
    --backgroundColor: #f0f5f9;
    --insideBackgroundColor: white;
    --highlightColor: #e2e6ea;
    --borderColor: #fc4f4f;
    --navigatorWidth: 250px;
}

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext");

.activityContent {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: calc(100vh - 50px);
    overflow: hidden;
    color: #808080;
    position: fixed;
    bottom: 0px;
}
