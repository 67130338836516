// Phone
@media (max-width: 600px) {
    @keyframes contentOut {
        from {
            transform: translate(0px);
        }
        to {
            transform: translate(20vw);
        }
    }

    @keyframes contentIn {
        from {
            transform: translate(20vw);
        }
        to {
            transform: translate(0vw);
        }
    }

    .CalendarContainer {
        display: flex;
        width: 100vw;
        height: 89%;
        position: fixed;
        left: 0px;
        // flex-direction: column;
        // align-items: center;
        // justify-content: center;
        transform: translate(20vw);

        &.open {
            animation-name: contentOut;
            animation-duration: 0.6s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.43, 0.43, 0.05, 0.99);
        }

        &.closed {
            animation-name: contentIn;
            animation-duration: 0.6s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.68, 0.16, 0.27, 1);
        }

        .LocationSelector {
            width: 100%;
            height: 100%;
            background-color: var(--backgroundColor);
            flex-direction: column;
            display: flex;
            align-items: center;

            .row {
                width: 100%;
                justify-content: center;
                align-items: center;
            }
        }
        .Calendar {
            width: 40%;
        }
        .TimeSelector {
            width: 30%;
            flex-direction: column;
            display: flex;
            align-items: center;
        }
    }

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        input {
            margin-bottom: 0.5rem;
            margin-right: 0.5rem;
        }
    }

    .CalendarWeek {
        width: 100%;
        height: 100%;

        .arrowIcon {
            width: 4%;
            cursor: pointer;

            &_right {
                width: 4%;
                cursor: pointer;
                transform: rotateY(180deg);
            }
        }
    }
}

//Tablet
@media (min-width: 600px) and (max-width: 1080px) {
    @keyframes contentOut {
        from {
            transform: translate(0px);
        }
        to {
            transform: translate(20vw);
        }
    }

    @keyframes contentIn {
        from {
            transform: translate(20vw);
        }
        to {
            transform: translate(0vw);
        }
    }

    .CalendarContainer {
        display: flex;
        width: 100vw;
        height: 89%;
        position: fixed;
        left: 0px;
        // flex-direction: column;
        // align-items: center;
        // justify-content: center;
        transform: translate(20vw);

        &.open {
            animation-name: contentOut;
            animation-duration: 0.6s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.43, 0.43, 0.05, 0.99);
        }

        &.closed {
            animation-name: contentIn;
            animation-duration: 0.6s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.68, 0.16, 0.27, 1);
        }

        .LocationSelector {
            width: 15%;
            flex-direction: column;
            display: flex;
            align-items: center;

            .row {
                width: 100%;
                justify-content: center;
                align-items: center;
            }
        }
        .Calendar {
            width: 40%;
        }
        .TimeSelector {
            width: 30%;
            flex-direction: column;
            display: flex;
            align-items: center;
        }
    }

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        input {
            margin-bottom: 0.5rem;
            margin-right: 0.5rem;
        }
    }

    .CalendarWeek {
        width: 85%;

        .arrowIcon {
            width: 4%;
            cursor: pointer;

            &_right {
                width: 4%;
                cursor: pointer;
                transform: rotateY(180deg);
            }
        }
    }
}

//PC
@media (min-width: 1080px) {
    @keyframes contentOut {
        from {
            transform: translate(0px);
        }
        to {
            transform: translate(20vw);
        }
    }

    @keyframes contentIn {
        from {
            transform: translate(20vw);
        }
        to {
            transform: translate(0vw);
        }
    }

    .CalendarContainer {
        position: fixed;
        bottom: 0px;
        display: flex;
        align-items: center;
        width: calc(100vw - 250px);
        transform: translate(250px);
        height: calc(100% - 50px);

        &.open {
            animation-name: contentOut;
            animation-duration: 0.6s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.43, 0.43, 0.05, 0.99);
        }

        &.closed {
            animation-name: contentIn;
            animation-duration: 0.6s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.68, 0.16, 0.27, 1);
        }

        .LocationSelector {
            width: 15%;
            margin: 0px 20px 0px 20px;
            flex-direction: column;
            display: flex;
            align-items: flex-start;
            overflow-x: hidden;
            text-overflow: ellipsis;

            .row {
                width: 100%;
                justify-content: center;
                align-items: center;
            }
        }
        .Calendar {
            width: 40%;
        }
        .TimeSelector {
            width: 30%;
            flex-direction: column;
            display: flex;
            align-items: center;
        }
    }

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        input {
            margin-bottom: 0.5rem;
            margin-right: 0.5rem;
        }
    }

    .CalendarWeek {
        width: 82%;
        height: 93%;

        .arrowIcon {
            width: 4%;
            cursor: pointer;

            &_right {
                width: 4%;
                cursor: pointer;
                transform: rotateY(180deg);
            }
        }
    }
}
